import { Link, useForm } from '@inertiajs/react';
import { Button, Input } from '../../../shared/components';
import { LoginLayout } from '../../layouts';

const ResetPassword = ({ email, token }) => {
    const { data, setData, processing, errors, post } = useForm({
        email,
        token,
        password: '',
        password_confirmation: '',
    });

    const handleSubmit = e => {
        e.preventDefault();

        post('/reset-password');
    };

    return (
        <form onSubmit={handleSubmit}>
            <p className='mb-11 mt-4 text-lg'>Wachtwoord resetten</p>

            <Input
                autoComplete='email'
                placeholder='E-mailadres'
                type='email'
                readOnly
                value={data.email || ''}
                onChange={e => setData('email', e.target.value)}
                error={errors.email}
            />
            <Input
                autoComplete='new-password'
                autoFocus={true}
                placeholder='Wachtwoord'
                type='password'
                value={data.password || ''}
                onChange={e => setData('password', e.target.value)}
                error={errors.password}
            />
            <Input
                autoComplete='new-password'
                placeholder='Wachtwoord bevestigen'
                type='password'
                value={data.password_confirmation || ''}
                onChange={e => setData('password_confirmation', e.target.value)}
            />
            <div className='flex items-center justify-between gap-8'>
                <Button
                    content='Annuleren'
                    variant='secondary'
                    as={Link}
                    href='/login'
                />
                <Button
                    content='Opslaan'
                    type='submit'
                    disabled={processing}
                    loading={processing}
                />
            </div>
        </form>
    );
};

ResetPassword.layout = page => <LoginLayout>{page}</LoginLayout>;

export default ResetPassword;
