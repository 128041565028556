import { createInertiaApp } from '@inertiajs/react';
import { createRoot } from 'react-dom/client';

import './../../css/platform/main.css';

createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./views/**/*.jsx', { eager: true });
        return pages[`./views/${name}.jsx`];
    },
    setup({ el, App, props }) {
        createRoot(el).render(<App {...props} />);
    },
});
