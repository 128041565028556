import { useForm } from '@inertiajs/react';
import { Button, Input } from '../../../shared/components';
import { LoginLayout } from '../../layouts';

const AltTwoFactorChallenge = () => {
    const { data, setData, processing, errors, post } = useForm();

    const handleSubmit = e => {
        e.preventDefault();

        post('/alt-two-factor-challenge');
    };

    return (
        <form onSubmit={handleSubmit}>
            <p className='mb-11 mt-4 text-lg'>Log in op jouw account</p>
            <Input
                autoFocus={true}
                autoComplete='one-time-code'
                placeholder='Geheime code'
                label='U ontvangt een geheime code per e-mail'
                value={data?.code || ''}
                onChange={e => setData('code', e.target.value)}
                error={errors.code}
            />
            <Button
                content='Versturen'
                type='submit'
                disabled={processing}
                loading={processing}
            />
        </form>
    );
};

AltTwoFactorChallenge.layout = page => <LoginLayout>{page}</LoginLayout>;

export default AltTwoFactorChallenge;
