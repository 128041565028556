import { Link, useForm, usePage } from '@inertiajs/react';
import { Alert, Button, Input } from '../../../shared/components';
import { LoginLayout } from '../../layouts';

const ForgotPassword = () => {
    const { flash } = usePage().props;
    const { data, setData, processing, errors, post } = useForm({
        email: '',
    });

    const handleSubmit = e => {
        e.preventDefault();

        post('/forgot-password');
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <p className='mb-11 mt-4 text-lg'>Wachtwoord resetten</p>

                {flash?.status && <Alert message={flash.status} />}

                <Input
                    autoFocus={true}
                    autoComplete='email'
                    placeholder='E-mailadres'
                    type='email'
                    value={data?.email || ''}
                    onChange={e => setData('email', e.target.value)}
                    error={errors.email}
                />
                <div className='flex items-center justify-between gap-8'>
                    <Button
                        content='Annuleren'
                        variant='secondary'
                        as={Link}
                        href='/login'
                    />
                    <Button
                        content='Opslaan'
                        type='submit'
                        disabled={processing}
                        loading={processing}
                    />
                </div>
            </form>
        </>
    );
};

ForgotPassword.layout = page => <LoginLayout>{page}</LoginLayout>;

export default ForgotPassword;
